.form-wrapper {

  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .with-checkbox {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &> div {
    align-self: flex-start;
    vertical-align: middle;

    @media (max-width: 575px) {
      flex-basis: 100%;
    }

    input[type=checkbox] {
      vertical-align: text-bottom;
    }

    .input-wrapper {
      height: 38px;
      vertical-align: middle;
      padding-top: 8px;

      &.code {
        @media (max-width: 767px) {
          min-width: 84px;
          &+input {
            width: calc(100% - 92px); /*84 + 8 */
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .flex-sm-grow-2 {
    flex-grow: 2!important;
  }
  .code+input {
    width: 67%;
  }
}

.captcha-wrapper {
  @media (max-width: 428px) {
    width: 100%;
  }
}

.send-button-wrapper {
  @media (min-width: 429px) {
    vertical-align: top;
    padding-top: 22px;
  }

  @media (max-width: 428px) {
    width: 100%;
  }
}

input[type=text] {
  height: 38px;
  vertical-align: bottom;
}

.send {
  width: 100%;
}

.parent-dropdown {

  &.show>.btn-primary.dropdown-toggle {
    background: white;
    color: black;
  }

  button {
    position: relative;
    width: 300px;
    height: 38px;
    text-align: left;
    background: white;
    color: black;

    @media (max-width: 575px) {
      width: 100%;
    }

    &:hover, &:active {
      background: white!important;
      color: black!important;
    }

    &:after {
      position: absolute;
      top: 16px;
      right: 7px;
    }
  }
}

.info {
  margin-left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: inline-block;
  background: var(--blue);
  color: white;
  font-weight: bold;
  text-align: center;
  &.small {
    width: 18px;
    height: 18px;
    border-radius: 18px;
  }
}

.add {
  margin-left: 4px;
  display: inline-block;
  background: #d9dadb;
  color: white;
  font-weight: bold;
  text-align: center;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  line-height: 16px;
  &:hover {
    cursor: pointer;
  }
}

.genre-info {
  background: var(--light-brown-dark)!important;
  max-width: 300px;
  z-index: 1000;
  text-align: left;
}