.header-wrapper {
  display: flex;

  .headers {
    width: calc(100% - 163px);

    @media (min-width: 768px) {
      br {
        display: none;
      }
    }

    &:not(.extended) {
      @media (max-width: 767px) {
        white-space: nowrap;
      }
    }
  }

  h1 {
    text-align: initial;
  }
}

.langs {
  margin-left: auto;
}

@media (min-width: 768px) {
  .langs {
    white-space: nowrap;
  }
}


@media (min-width: 992px) {
  .langs {
    position: absolute;
    top: 0;
    right: 12px;
  }
}

@media (max-width: 380px) {
  .logo-wrapper {
    margin-left: -15px;
  }

  .logo {
    max-width: 100%;
  }

  .slogan {
    font-size: 14px;
  }
}