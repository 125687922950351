@import '../../css-vars.scss';

.link {
  text-align: right;
  .framed {
    border: 1px var(--link-color) solid;
  }
  .thumb {
    width: 24px;
    height: 24px;
  }
}