@import '../../css-vars';

button.btn-primary.dropdown-toggle.custom {
    background-color: transparent;
    border-color: var(--blue);
    color: var(--black-text);
    &:hover, &:active, &.dropdown-toggle {
        background-color: transparent;
        color: var(--blue);
        &:after {
            border-top-color: var(--black-text);
            border-bottom-color: var(--black-text);
        }
    }

}

.dropdown-item.custom.active {
    cursor: text;
    background: var(--blue);
}