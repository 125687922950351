.download-wrapper {
  display: flex;

  .or {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .bottom-link {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 100%;

    .visit {
      width: calc(100% - 220px);
      display: inline-block;
      text-align: right;
      vertical-align: middle;
      margin-right: 44px;
    }
  }

  .arrow {
    font-size: 48px;
    position: absolute;
  }

  .arrow.left {
    top: -12px;
    left: auto;
  }

  .arrow.left + span {
    margin-left: 44px;
  }

  .arrow.right {
    bottom: -5px;
    right: 177px;
  }

}