@import './src/Components/Screenshots/Screenshots';

.pre {
  text-decoration: underline;
}

.migration.screenshots .splide {
  @media (min-width: 768px) {
    &__arrow {
      top: 219px;
    }
  }

  @media (min-width: 992px) {
    &__arrow {
      top: 290px;
    }
  }

  @media (min-width: 1200px) {
    &__arrow {
      top: 333px;
    }
  }
}