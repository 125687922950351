.clickable-wrapper {
  position: relative;
  &:hover {
    cursor: pointer;
  }
  margin-right: 16px;
}

.exp-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  position: absolute;
  bottom: 5px;
  padding: 3px;

  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.parent-genre {
  text-align: left;
  &:nth-child(even) {
    & > .row {
      background: rgba(142, 132, 123, 0.25); /* light brown dark with opacity 0.5*/
    }

    .child-genre {
      &:nth-child(even) {
        background: rgba(142, 132, 123, 0.12); /* light brown dark with opacity 0.25*/
      }
    }
  }

  &:nth-child(odd) {
    .child-genre {
      &:nth-child(odd) {
        background: rgba(142, 132, 123, 0.12); /* light brown dark with opacity 0.25*/
      }
    }
  }

  .child-genre:last-child {
    border-bottom: 1px var(--light-brown-dark) solid;
  }

  color: var(--light-brown-dark);

  .custom {
    color: black;
  }

  .new {
    color: var(--dark-red);
  }
}

.legend {
  li:first-child {
    color: var(--light-brown-dark);
  }

  li:last-child {
    color: var(--dark-red);
  }
}

.summing {
  border-bottom: 1px var(--light-brown-dark) solid;
}