.loading-wrapper {
  .loader {
    margin: 0 auto;
  }
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  opacity: 0.5;

  img {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}