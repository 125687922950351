@import '../../css-vars';

.screenshots {
  .splide {

    @media (min-width: 768px) {
      &__arrow {
        &--prev:not(:disabled) {
          &:before {
            display: block;
            content: ' ';
            width: 60px;
            height: 388px;
            background: var(--light-brown-dark);
            opacity: 0.5;
            position: absolute;
            z-index: 1;
            top: -202px;
            left: -10px;
          }
        }

        &--next:not(:disabled) {
          &:before {
            display: block;
            content: ' ';
            width: 60px;
            height: 388px;
            background: var(--light-brown-dark);
            opacity: 0.5;
            position: absolute;
            z-index: 1;
            top: -202px;
            right: -10px;
          }
        }
      }
    }

    @media (min-width: 992px) {
      &__arrow {
        &--prev:not(:disabled), &--next:not(:disabled) {
          &:before {
            top: -274px;
            height: 532px;
          }
        }
      }
    }

    @media (min-width: 1200px) {
      &__arrow {
        &--prev:not(:disabled), &--next:not(:disabled) {
          &:before {
            top: -316px;
            height: 638px;
          }
        }
      }
    }

    &__track {
      margin-bottom: 25px;
    }

    &__pagination {
      bottom: -1em;

      &__page {
        &.is-active {
          background: var(--light-brown-dark);
        }
      }
    }
  }

  button:focus {
    outline: none;
  }

  .image-wrapper {
    padding-left: 5px;
    padding-right: 5px;

    .screenshot {
      max-width: 100%;
      border: 1px var(--black-text) solid;
    }
  }

  .text-wrapper {
    text-align: left;
  }
}