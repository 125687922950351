@import 'css-vars.scss';

body {
  color: var(--black-text);
  text-align: justify;

  a {
    color: var(--link-color);

    &:hover {
      color: var(--blue);
    }
  }

  .error {
    color: red;
    &.global {
      font-weight: bold;
    }
    &:not(.global) {
      font-size: small;
    }
  }
}
