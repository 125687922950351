.ru {
  background: white;
  display: inline-block;
  width: 21.33px;
  height: 16px;
}

.rings {
  font-size: 0.5px;
  list-style: none;
  margin: 5px auto;
  position: absolute;

  li {
    border-radius: 1em;
    font-size: 1em;
    height: 12em;
    margin-left: 1em;
    margin-top: 1em;
    position: absolute;
    width: 12em;

    &:before, &:after {
      border-radius: 50%;
      position: absolute;
    }

    &:before {
      content: "";
    }
  }

  li:after {
    border: 1.15em solid #000;
    bottom: 0;
    content: "";
    left: -0.1em;
    right: -0.1em;
    top: -0.1em;
  }

  .blue {
    left: 0;
    top: 0;
    z-index: 10;

    &:after {
      border-color: #0080cb;
    }

    +.chain {
      z-index: 24;

      &:before, &:after {
        border-bottom-color: transparent;
      }
    }
  }

  .yellow {
    left: 6.8em;
    top: 5.7em;
    z-index: 20;

    &:after {
      border-color: #fdb408;
    }

    +.chain {
      z-index: 23;

      &:before, &:after {
        border-right-color: transparent;
      }
    }
  }

  .black {
    left: 13.6em;
    top: 0;
    z-index: 21;

    &:after {
      border-color: #000;
    }
  }

  .green {
    left: 20.4em;
    top: 5.7em;
    z-index: 20;

    &:after {
      border-color: #00a84c;
    }

    +.chain  {
      z-index: 23;

      &:before, &:after {
        border-top-color: transparent;
      }
    }
  }

  .red {
    left: 27.2em;
    top: 0;
    z-index: 10;

    &:after {
      border-color: #f02c49;
    }

    +.chain {
      z-index: 23;

      &:before, &:after {
        border-left-color: transparent;
      }
    }
  }
}
