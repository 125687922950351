@import '../../css-vars.scss';

.button {
  cursor: pointer;
  background: var(--blue);
  color: var(--white);
  padding: 5px 10px;
  display: inline-block;
  border-radius: 15px;
  font-weight: 500;

  &:hover:not(.disabled) {
    -webkit-box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 40%);
    -moz-box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 40%);
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 40%);
  }

  &.disabled {
    cursor: auto;
    opacity: 0.7;
  }

  &.cancel {
    background: var(--red);

  }
}
